import React, { Component } from 'react';
import {graphql} from 'gatsby'
import zparse from 'helpers/parse';
import Layout from 'components/layout/internal';
import SEO from 'shared/seo'
import style from 'components/layout/internal.module.scss';

export default class ContentPageGet extends Component {
	
	liveRefresh() {
		fetch(`${process.env.GATSBY_ZMS_API_URL}get${this.page.uri}`)
			.then(response => response.json())
			.then(({data}) => {
				if (data.length) {
					this.setState({
						content: data[0].content.main,
						title: data[0].pageTitle,
						meta: data[0].meta,
					})
				}
			})
			.catch(err => console.log);
	}

	constructor(props) {
		super(props);
		this.page = (this.props.data.allContentPage && this.props.data.allContentPage.edges.length ? this.props.data.allContentPage.edges[0].node : null);
		this.state = {
			content: (this.page ? this.page.content.main : 'Page Not Found'),
			title: (this.page ? this.page.pageTitle : 'Page Not Found'),
			meta: (this.page ? this.page.meta : '')
		}
		this.liveRefresh = this.liveRefresh.bind(this);
	}

	componentDidMount() {
		this.liveRefresh();
	}

	render() {
		return (
			<>
			<Layout>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-offset-2 medium-8 end">
							<h1 className={style.pageTitle}>{this.state.title}</h1>
							<section className={[style.mainContent, "main-content"].join(' ')}>
								{zparse(this.state.content)}
							</section>
						</div> 
					</div>
				</div>
			</Layout>
			<SEO title={this.state.title} meta={this.state.meta} />
			</>
		)
	}
}

export const query = graphql`
	query ($uri: String) {
	  allContentPage(filter: {uri: {eq: $uri}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
	        uri
	        content {
	          main
	        }
	        meta
	        pageTitle
	      }
	    }
	  }
	}
`